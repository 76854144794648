<div>
  <section [formGroup]="form">
    <div class="row address">
      <div class="col-md-6 pt-4">
        <!-- TYPE -->
        <inm-input-container [label]="'address_form.fields.type.label' | staticContent" class="col-3">
          <div>
            <dd>
              <input
                type="text"
                class="address"
                [placeholder]="'address_form.fields.type.placeholder' | staticContent"
                formControlName="type"
                class="inm-textinput__input inm-disabled"
              />
            </dd>
          </div>
        </inm-input-container>
      </div>
      <div class="col-md-6 mt-5" *ngIf="useExistingAddress">
        <inm-input-container class="col-2">
          <div>
            <dd>
              <inm-checkbox [checked]="!useExistingAddress" (change)="toggleFormEdit($event)">
                {{ 'common.different_address' | staticContent }}
              </inm-checkbox>
            </dd>
          </div>
        </inm-input-container>
      </div>

      <div class="col-md-6 mt-5" *ngIf="showAviationCheckbox">
        <inm-input-container class="col-2">
          <div>
            <dd>
              <inm-checkbox [checked]="defaultAviationCheckboxValue" (change)="toggleAviation($event)">
                {{ 'common.aviation' | staticContent }}
              </inm-checkbox>
            </dd>
          </div>
        </inm-input-container>
      </div>
    </div>

    <div class="row" *ngIf="form.get('type').value === addressTypes.EMERGENCY">
      <div class="col-6">
        <ng-container *ngIf="addressOptions$ | async as addressOptions">
          <inm-input-container
            *ngIf="addressOptions.length"
            [label]="'address_form.fields.address.label' | staticContent"
            class="col-6"
          >
            <inm-typeahead
              class="addressDropdown"
              [disabled]="form.disabled"
              [resetValue]="true"
              [options]="addressOptions"
              valueKey="value"
              labelKey="label"
              [placeholder]="'address_form.fields.address.placeholder' | staticContent"
              [inputFormControl]="form.get('address')"
            >
            </inm-typeahead>
          </inm-input-container>
        </ng-container>
      </div>
    </div>

    <div class="row">
      <inm-input-container
        *ngIf="countryOptions.length > 0"
        [label]="'address_form.fields.country.label' | staticContent"
        class="col-6"
      >
        <inm-typeahead
          #countryDropdownTypeahead
          class="countryDropdown"
          [disabled]="form.disabled"
          [resetValue]="true"
          [options]="countryOptions"
          valueKey="value"
          labelKey="label"
          [placeholder]="'address_form.fields.country.placeholder' | staticContent"
          [inputFormControl]="form.controls['isoCountryCode']"
        >
        </inm-typeahead>
        <inm-validation-message
          [control]="form.controls['isoCountryCode']"
          [label]="'address_form.fields.country.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>

      <inm-input-container
        *ngIf="defaultAviationCheckboxValue && showAviationCheckbox"
        [label]="'address_form.fields.airportCode.label' | staticContent"
        class="col-6"
        [tooltipMessage]="'address_form.fields.airportCode.tooltip' | staticContent"
        tooltipPlacement="top"
      >
        <inm-search-box
          formControlName="airportCode"
          [placeholder]="'address_form.fields.airportCode.placeholder' | staticContent"
          [results]="results$ | async"
          valueKey="value"
          labelKey="label"
          (search)="search($event)"
          [multiple]="false"
          [minSearchLength]="1"
        >
        </inm-search-box>
        <inm-validation-message
          [control]="form.controls['airportCode']"
          [label]="'address_form.fields.airportCode.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>

      <inm-input-container [label]="'address_form.fields.city.label' | staticContent" class="col-6">
        <input
          type="text"
          [placeholder]="'address_form.fields.city.placeholder' | staticContent"
          formControlName="city"
          class="inm-textinput__input"
        />
        <inm-validation-message
          [control]="form.controls['city']"
          [label]="'address_form.fields.city.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>

      <inm-input-container [label]="'address_form.fields.addressLine1.label' | staticContent" class="col-6">
        <input
          type="text"
          [placeholder]="'address_form.fields.addressLine1.placeholder' | staticContent"
          formControlName="addressLine1"
          class="inm-textinput__input"
        />
        <inm-validation-message
          [control]="form.controls['addressLine1']"
          [label]="'address_form.fields.addressLine1.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>

      <inm-input-container
        [label]="'address_form.fields.addressLine2.label' | staticContent"
        class="col-6"
        [optional]="true"
      >
        <input
          type="text"
          [placeholder]="'address_form.fields.addressLine2.placeholder' | staticContent"
          formControlName="addressLine2"
          class="inm-textinput__input"
        />
        <inm-validation-message
          [control]="form.controls['addressLine2']"
          [label]="'address_form.fields.addressLine2.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>

      <inm-input-container
        *ngIf="stateProvinces.length > 0"
        [label]="'address_form.fields.stateProvince.label' | staticContent"
        class="col-6"
      >
        <inm-tree-select
          [multiple]="false"
          valueKey="label"
          labelKey="label"
          [items]="stateProvinces"
          formControlName="stateProvince"
          [placeholder]="'address_form.fields.stateProvince.placeholder' | staticContent"
          [loading]="(provinceLoader$ | async)?.loading"
        >
        </inm-tree-select>
        <inm-validation-message
          [control]="form.controls['stateProvince']"
          [label]="'address_form.fields.stateProvince.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>

      <inm-input-container
        [label]="'address_form.fields.addressLine3.label' | staticContent"
        class="col-6"
        [optional]="true"
      >
        <input
          type="text"
          [placeholder]="'address_form.fields.addressLine3.placeholder' | staticContent"
          formControlName="addressLine3"
          class="inm-textinput__input"
        />
        <inm-validation-message
          [control]="form.controls['addressLine3']"
          [label]="'address_form.fields.addressLine3.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>
      <!-- Enable it in 3B
      <inm-input-container
        [label]="'address_form.fields.careOfName.label' | staticContent"
        class="col-6"
        [optional]="true"
      >
        <input
          type="text"
          [placeholder]="'address_form.fields.careOfName.placeholder' | staticContent"
          formControlName="careOfName"
          class="inm-textinput__input"
        />
        <inm-validation-message
          [control]="form.controls['careOfName']"
          [label]="'address_form.fields.careOfName.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>
    -->
      <inm-input-container
        [label]="'address_form.fields.postcode.label' | staticContent"
        class="col-6"
        [optional]="!postCodeHint"
      >
        <input
          type="text"
          [placeholder]="'address_form.fields.postcode.placeholder' | staticContent"
          formControlName="postalCode"
          class="inm-textinput__input"
        />
        <inm-validation-message
          [control]="form.controls['postalCode']"
          [label]="'address_form.fields.postcode.label' | staticContent"
        >
        </inm-validation-message>
        <inm-validation-message
          [control]="form.controls['postalCode']"
          [label]="('address_form.fields.postcode.label' | staticContent) + '. ' + postCodeHint"
        >
        </inm-validation-message>
      </inm-input-container>
    </div>
  </section>
</div>
