<inm-panel [formGroup]="editContactInfoForm" class="main__status_col pe-0 mb-0 inline-edit-form">
  <div class="row">
    <div class="col-6">
      <div class="inm-h5-style mb-3 mt-4 row pt-4">
        <div class="col-6">
          <input
            type="text"
            [placeholder]="'contact_form.fields.firstName.placeholder' | staticContent"
            formControlName="givenName"
            class="inm-textinput__input"
          />
          <inm-validation-message
            *ngIf="givenNameCtrl.invalid && (givenNameCtrl.dirty || givenNameCtrl.touched)"
            [control]="givenNameCtrl"
            [label]="'contact_form.fields.firstName.label' | staticContent"
          >
          </inm-validation-message>
        </div>
        <div class="col-6">
          <input
            [placeholder]="'contact_form.fields.lastName.placeholder' | staticContent"
            type="text"
            formControlName="surname"
            class="inm-textinput__input"
          />
          <inm-validation-message
            *ngIf="surnameCtrl.invalid && (surnameCtrl.dirty || surnameCtrl.touched)"
            [control]="surnameCtrl"
            [label]="'contact_form.fields.lastName.label' | staticContent"
          >
          </inm-validation-message>
        </div>
      </div>
      <div class="row mb-2">
        <inm-icon
          class="ms-4 contact-icons col-2"
          name="{{ iconNames.Phone }}"
          size="medium"
          [color]="iconColors.DarkBlue"
        >
        </inm-icon>
        <input
          type="text"
          [placeholder]="'contact_form.fields.phone.placeholder' | staticContent"
          formControlName="phone"
          class="inm-textinput__input col-8 contact-input"
        />
        <div class="col-9 ms-5 pe-0">
          <inm-validation-message
            *ngIf="phoneCtrl.invalid && (phoneCtrl.dirty || phoneCtrl.touched)"
            [control]="phoneCtrl"
            [label]="'contact_form.fields.phone.label' | staticContent"
          >
          </inm-validation-message>
        </div>
      </div>
      <div class="row mb-2">
        <inm-icon
          class="ms-4 contact-icons col-2"
          name="{{ iconNames.Smartphone }}"
          size="medium"
          [color]="iconColors.DarkBlue"
        >
        </inm-icon>
        <input
          [placeholder]="'contact_form.fields.mobile.placeholder' | staticContent"
          type="text"
          formControlName="mobile"
          class="inm-textinput__input col-8 contact-input"
        />
        <div class="col-9 ms-5 pe-0">
          <inm-validation-message
            *ngIf="mobileCtrl.invalid && (mobileCtrl.dirty || mobileCtrl.touched)"
            [control]="mobileCtrl"
            [label]="'contact_form.fields.mobile.label' | staticContent"
          >
          </inm-validation-message>
        </div>
      </div>
      <div class="row mb-2">
        <inm-icon
          class="ms-4 contact-icons col-2"
          name="{{ iconNames.Fax }}"
          size="medium"
          [color]="iconColors.DarkBlue"
        >
        </inm-icon>
        <input
          [placeholder]="'contact_form.fields.fax.placeholder' | staticContent"
          type="text"
          formControlName="fax"
          class="inm-textinput__input col-8 contact-input"
        />
      </div>
      <div class="row mb-2">
        <inm-icon
          class="ms-4 contact-icons col-2"
          name="{{ iconNames.Envelope }}"
          size="medium"
          [color]="iconColors.DarkBlue"
        >
        </inm-icon>
        <input
          type="text"
          [placeholder]="'contact_form.fields.email.placeholder' | staticContent"
          formControlName="email"
          class="inm-textinput__input col-8 contact-input"
        />
        <div class="col-9 ms-5 pe-0">
          <inm-validation-message
            *ngIf="emailCtrl.invalid && (emailCtrl.dirty || emailCtrl.touched)"
            [control]="emailCtrl"
            [label]="'contact_form.fields.email.label' | staticContent"
          >
          </inm-validation-message>
        </div>
      </div>
      <div class="row mb-2">
        <inm-icon
          class="ms-4 contact-icons col-2"
          name="{{ iconNames.Envelope }}"
          size="medium"
          [color]="iconColors.DarkBlue"
        >
        </inm-icon>
        <input
          type="text"
          [placeholder]="'contact_form.fields.alternateEmail.placeholder' | staticContent"
          formControlName="alternateEmail"
          class="inm-textinput__input col-8 contact-input"
        />
        <div class="col-9 ms-5 pe-0">
          <inm-validation-message
            *ngIf="alternateEmailCtrl.invalid && (alternateEmailCtrl.dirty || alternateEmailCtrl.touched)"
            [control]="alternateEmailCtrl"
            [label]="'contact_form.fields.alternateEmail.label' | staticContent"
          >
          </inm-validation-message>
        </div>
      </div>
    </div>
    <div class="col-6 p-3">
      <inm-input-container [optional]="true" [label]="'contact_form.fields.prefix.label' | staticContent">
        <inm-tree-select
          [items]="prefixOptions$ | async"
          valueKey="value"
          labelKey="label"
          [placeholder]="'contact_form.fields.prefix.placeholder' | staticContent"
          formControlName="prefix"
          class="inm-textinput__input"
        >
        </inm-tree-select>
      </inm-input-container>
      <inm-input-container [optional]="true" [label]="'contact_form.fields.suffix.label' | staticContent">
        <inm-tree-select
          [items]="suffixOption$ | async"
          valueKey="value"
          labelKey="label"
          [placeholder]="'contact_form.fields.suffix.placeholder' | staticContent"
          formControlName="suffix"
          class="inm-textinput__input"
        >
        </inm-tree-select>
      </inm-input-container>
      <inm-input-container [optional]="true" [label]="'contact_form.fields.jobTitle.label' | staticContent">
        <input
          type="text"
          [placeholder]="'contact_form.fields.jobTitle.placeholder' | staticContent"
          formControlName="jobTitle"
          class="inm-textinput__input"
        />
      </inm-input-container>
    </div>
  </div>
</inm-panel>
