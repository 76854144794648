<inm-panel>
  <h4 class="title ms-2">{{ '.title' | staticContent }}</h4>
  <button inm-button class="close" kind="icon" icon="Close" iconColor="Grey" (click)="bsModalRef.hide()"></button>
  <ng-container *ngIf="!isSearching && checkArAccount(); else siblingAccountCheck">
    <div class="account-move mt-3">
      <h5>{{ '.no_ar_account' | staticContent }}</h5>
    </div>
  </ng-container>

  <ng-template #siblingAccountCheck>
    <ng-container
      *ngIf="siblingAccountCount && siblingAccountCount === 2 && !allowChildAccountMove; else checkAvailableParent"
    >
      <div class="account-move mt-3">
        <inm-icon name="{{ iconNames.Error }}" size="regular" [color]="iconColors.MidBlue" class="mx-auto d-flex mb-3">
        </inm-icon>
        <p class="title">{{ '.confirm_move' | staticContent }}</p>
      </div>
      <inm-panel-actions class="px-3">
        <button inm-button kind="secondary" (click)="bsModalRef.hide()">
          {{ 'buttons.go_back' | staticContent }}
        </button>
        <button inm-button kind="primary" (click)="allowMove()">
          {{ 'buttons.move_account' | staticContent }}
        </button>
      </inm-panel-actions>
    </ng-container>
  </ng-template>

  <ng-template #checkAvailableParent>
    <ng-container *ngIf="allowChildAccountMove">
      <form name="accountMoveForm" [formGroup]="accountMoveForm" role="form" novalidate>
        <div class="account-move mt-3">
          <!--Account Move Form-->
          <inm-panel [title]="'.account_move' | staticContent">
            <ng-container>
              <div
                class="alert account-move-highlight"
                role="alert"
                *ngIf="changeRequestRelatedLinks.needAccountMoveRequest"
              >
                <inm-icon
                  class="icon me-2"
                  [color]="iconColors.DarkBlue"
                  name="{{ iconNames.Info }}"
                  size="small"
                ></inm-icon>
                <div
                  *ngIf="
                    !(
                      changeRequestRelatedLinks.hasApprovedMovedToAccount &&
                      changeRequestRelatedLinks.movedTo &&
                      changeRequestRelatedLinks.movedTo !== parentAccount?.accountNumber
                    )
                  "
                >
                  <span> {{ this.changeRequestRelatedLinks.message }}</span>

                  <span class="font-weight-bold ms-1"> <a (click)="requestMoveApproval()"> Here. </a></span>
                </div>
                <span
                  class="ms-1"
                  *ngIf="
                    changeRequestRelatedLinks.hasApprovedMovedToAccount &&
                    changeRequestRelatedLinks.movedTo &&
                    changeRequestRelatedLinks.movedTo !== parentAccount?.accountNumber
                  "
                >
                  {{ '.approved_moved_to' | staticContent }} {{ changeRequestRelatedLinks.movedTo }}</span
                >
              </div>
              <div class="expandPanelIcon">
                <inm-icon
                  (click)="expandPanelAction('accountMoveFrom')"
                  [name]="expandedPanel.accountMoveFrom ? iconNames.ChevronUp : iconNames.ChevronDown"
                  size="small"
                  [color]="iconColors.Grey"
                  class="expandPanelIcon-size"
                ></inm-icon>
              </div>
            </ng-container>
            <div [ngClass]="{ collapsed: this.expandedPanel.accountMoveFrom }">
              <div class="row">
                <inm-input-container [label]="'.current_cle' | staticContent" class="col-lg-6">
                  {{ accountDetails.legalEntityName }}
                </inm-input-container>
                <inm-input-container [label]="'.current_ar_account' | staticContent" class="col-lg-6">
                  {{ parentAccount?.name }}, {{ parentAccount?.accountNumber }}
                </inm-input-container>
                <inm-input-container [label]="'.selected_billing_account_name' | staticContent" class="col-lg-6">
                  {{ accountDetails.name }}, {{ accountDetails.accountNumber }}
                </inm-input-container>
                <inm-input-container [label]="'.new_ar_account.label' | staticContent" class="col-lg-6">
                  <div class="child-account-info" *ngIf="showUnmatahedSalesOrgs">
                    <inm-icon
                      class="icon ms-2"
                      name="{{ iconNames.Info }}"
                      size="small"
                      [tooltip]="getTooltipMessage()"
                    ></inm-icon>
                  </div>
                  <inm-search-box
                    [placeholder]="'.new_ar_account.placeholder' | staticContent"
                    formControlName="newARAccount"
                    [results]="parentAccountsList"
                    labelKey="accountDetail"
                    (search)="searchAccount($event)"
                  ></inm-search-box>
                  <inm-validation-message
                    [control]="accountMoveForm.controls['newARAccount']"
                    [label]="'.new_ar_account.label' | staticContent"
                  >
                  </inm-validation-message>
                </inm-input-container>
              </div>
            </div>
          </inm-panel>
        </div>
        <!--Show Parnter fields when move INM6/IM17 -->
        <ng-container
          *ngIf="
            accountDetails.accountGroup === accountGroupList.INM6 ||
            accountDetails.accountGroup === accountGroupList.IM17
          "
        >
          <inm-panel [title]="'.parnter' | staticContent">
            <ng-container>
              <div class="expandPanelIcon">
                <inm-icon
                  (click)="expandPanelAction('partnerForm')"
                  [name]="expandedPanel.partnerForm ? iconNames.ChevronUp : iconNames.ChevronDown"
                  size="small"
                  [color]="iconColors.Grey"
                  class="expandPanelIcon-size"
                ></inm-icon>
              </div>
            </ng-container>
            <div [ngClass]="{ collapsed: this.expandedPanel.partnerForm }">
              <div class="row">
                <inm-input-container [label]="'.contractParty.title' | staticContent" class="col-lg-6">
                  <inm-search-box
                    [placeholder]="'.contractParty.placeholder' | staticContent"
                    formControlName="contractParty"
                    [results]="contractPartyList"
                    labelKey="accountDetail"
                    (search)="searchContractParty($event)"
                  ></inm-search-box>
                  <inm-validation-message
                    [control]="contractPartyCtrl"
                    [label]="'.contractParty.title' | staticContent"
                  >
                  </inm-validation-message>
                </inm-input-container>
                <inm-input-container [label]="'.managementParty.title' | staticContent" class="col-lg-6">
                  <inm-search-box
                    [placeholder]="'.managementParty.placeholder' | staticContent"
                    formControlName="managementParty"
                    [results]="managementPartyList"
                    labelKey="accountDetail"
                    (search)="searchManagementParty($event)"
                  ></inm-search-box>
                  <inm-validation-message
                    [control]="managementPartyCtrl"
                    [label]="'.managementParty.title' | staticContent"
                  >
                  </inm-validation-message>
                </inm-input-container>

                <inm-input-container [optional]="true" [label]="'.shipOwner.title' | staticContent" class="col-lg-6">
                  <inm-search-box
                    [placeholder]="'.shipOwner.placeholder' | staticContent"
                    formControlName="shipOwner"
                    [results]="shipOwnerList"
                    labelKey="accountDetail"
                    (search)="searchShipOwner($event)"
                  ></inm-search-box>
                </inm-input-container>

                <inm-input-container [label]="'.dealer.title' | staticContent" class="col-lg-6">
                  <inm-search-box
                    [placeholder]="'.dealer.placeholder' | staticContent"
                    formControlName="dealerCode"
                    [results]="dealerList"
                    labelKey="accountDetail"
                    (search)="searchDealer($event)"
                  ></inm-search-box>
                  <inm-validation-message [control]="dealerCtrl" [label]="'.dealer.title' | staticContent">
                  </inm-validation-message>
                </inm-input-container>
              </div>
            </div>
          </inm-panel>
        </ng-container>

        <!--Add Outbound Feed Form -->

        <inm-account-add-outbound-feed-step
          #outboundFeedForm
          [allowCancelAndNext]="false"
          [allowDeselectAndAddMore]="true"
          [outboundFeedsValueLoaded]="true"
          [initialCheckAROBF]="false"
        >
          <div class="obf-title pb-3">{{ 'accounts.details.posting_account_move.obf' | staticContent }}</div>
        </inm-account-add-outbound-feed-step>

        <ng-container *ngIf="showWarningPrompt">
          <inm-checkbox [checked]="isConfirmed" (change)="toggleConfirm()" class="pb-3">
            {{ '.move_account_diff_cle' | staticContent }}
          </inm-checkbox>
        </ng-container>
        <ng-container *ngIf="showErrorMessage">
          <div class="outbound-feed-duplicate-message">
            <inm-icon name="{{ iconNames.Warning }}" color="StatusOrange"></inm-icon>
            {{ 'accounts.create.obf.duplicate_outbound_feed' | staticContent }}
          </div>
        </ng-container>
        <inm-panel-actions>
          <button inm-button kind="secondary" (click)="bsModalRef.hide()">
            {{ 'buttons.cancel' | staticContent }}
          </button>
          <button
            inm-button
            kind="primary"
            [disabled]="
              accountMoveForm.invalid ||
              (showWarningPrompt && !isConfirmed) ||
              outboundFeedForm?.form?.invalid ||
              !this.checkMandatoryOutboundFeedsValue()
            "
            (click)="confirm()"
          >
            {{ 'buttons.confirm' | staticContent }}
            <inm-icon
              *ngIf="
                accountMoveForm.valid &&
                (outboundFeedForm?.form?.valid || outboundFeedForm?.form?.disabled) &&
                !this.checkMandatoryOutboundFeedsValue()
              "
              class="icon ms-2"
              name="{{ iconNames.Info }}"
              size="small"
              [tooltip]="getTooltipText()"
            >
            </inm-icon>
          </button>
        </inm-panel-actions>
      </form>
    </ng-container>
  </ng-template>
</inm-panel>
