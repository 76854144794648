<ng-container>
  <inm-panel class="inm-panel--no-padding pb-4">
    <div class="row justify-content-end me-2 add-attachment-link">
      <a class="inm-link-create action addAttachment" (click)="addAttachmentModal()">
        {{ '.attachment' | staticContent }}
      </a>
    </div>
    <inm-table
      [displayedColumns]="displayedColumns"
      overflowStrategy="wrap"
      [loading]="attachmentList.loading"
      [dataSource]="attachmentList?.items"
      overflowStrategy="wrap"
    >
      <ng-container inmColumnDef="file_type">
        <th inm-header-cell *inmHeaderCellDef>
          {{ '.headers.type' | staticContent }}
        </th>
        <td inm-cell *inmCellDef="let attachment">
          {{ attachment.fileCategory || '–' }}
        </td>
      </ng-container>

      <ng-container inmColumnDef="file_name">
        <th inm-header-cell *inmHeaderCellDef>
          {{ '.headers.name' | staticContent }}
        </th>
        <td inm-cell *inmCellDef="let attachment">
          {{ attachment.fileName || '–' }}
        </td>
      </ng-container>

      <ng-container inmColumnDef="file_date">
        <th inm-header-cell *inmHeaderCellDef>
          {{ '.headers.date' | staticContent }}
        </th>
        <td inm-cell *inmCellDef="let attachment">
          {{ (attachment.uploadDate | inmDate: 'date') || '–' }}
        </td>
      </ng-container>

      <ng-container inmColumnDef="action">
        <th inm-header-cell *inmHeaderCellDef>
          {{ '.headers.action' | staticContent }}
        </th>
        <td inm-cell *inmCellDef="let attachment">
          <div class="actions">
            <button
              (click)="getDownloadUrl($event, attachment)"
              inm-button
              kind="icon"
              *ngIf="attachment.fileId"
              icon="{{ iconNames.Download }}"
              iconColor="mid-blue"
              [showRipple]="false"
              [tooltip]="'buttons.download' | staticContent"
            ></button>

            <button
              [inmPermission]="attachmentPermission"
              inm-button
              kind="icon"
              *ngIf="attachment.fileId"
              (click)="deleteAttachment($event, attachment)"
              icon="{{ iconNames.Bin }}"
              iconColor="mid-blue"
              iconSize="small"
              [showRipple]="false"
              [tooltip]="'buttons.delete' | staticContent"
            ></button>
          </div>
        </td>
      </ng-container>
    </inm-table>
  </inm-panel>
</ng-container>
