<div>
  <section [formGroup]="form">
    <div class="row address">
      <div class="col-md-6 pt-4">
        <!-- TYPE -->
        <inm-input-container [label]="'address_form.fields.type.label' | staticContent" class="col-2">
          <div>
            <dd>
              <input
                type="text"
                [placeholder]="'address_form.fields.type.placeholder' | staticContent"
                formControlName="type"
                class="inm-textinput__input inm-disabled"
              />
            </dd>
          </div>
        </inm-input-container>
      </div>
      <div class="col-md-6 mt-5" *ngIf="useExistingContact">
        <inm-input-container class="col-2">
          <div>
            <dd>
              <inm-checkbox [checked]="!useExistingContact" (change)="toggleFormEdit($event)">
                {{ 'common.different_contact' | staticContent }}
              </inm-checkbox>
            </dd>
          </div>
        </inm-input-container>
      </div>
    </div>

    <div class="row" *ngIf="form.get('type').value === contactTypes.EMERGENCY">
      <div class="col-6">
        <ng-container *ngIf="contactOptions$ | async as contactOptions">
          <inm-input-container
            *ngIf="contactOptions.length"
            [label]="'contact_form.fields.contact.label' | staticContent"
            class="col-6"
          >
            <inm-typeahead
              class="contactDropdown"
              [disabled]="form.disabled"
              [resetValue]="true"
              [options]="contactOptions"
              valueKey="value"
              labelKey="label"
              [placeholder]="'contact_form.fields.contact.placeholder' | staticContent"
              [inputFormControl]="form.get('contact')"
            >
            </inm-typeahead>
            <inm-validation-message
              [control]="form.controls['contact']"
              [label]="'address_form.fields.country.label' | staticContent"
            >
            </inm-validation-message>
          </inm-input-container>
        </ng-container>
      </div>
    </div>

    <div class="row">
      <inm-input-container [optional]="true" [label]="'contact_form.fields.prefix.label' | staticContent" class="col-6">
        <inm-tree-select
          [multiple]="false"
          valueKey="value"
          labelKey="label"
          [items]="prefixOptions$ | async"
          formControlName="prefix"
          [placeholder]="'contact_form.fields.prefix.placeholder' | staticContent"
        >
        </inm-tree-select>
      </inm-input-container>

      <inm-input-container
        [label]="'contact_form.fields.firstName.label' | staticContent"
        [optional]="true"
        class="col-6"
      >
        <input
          type="text"
          [disabled]="form.get('givenName').disabled"
          [placeholder]="'contact_form.fields.firstName.placeholder' | staticContent"
          formControlName="givenName"
          class="inm-textinput__input"
        />
        <inm-validation-message
          [control]="form.controls['givenName']"
          [label]="'contact_form.fields.firstName.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>

      <inm-input-container
        [label]="'contact_form.fields.phone.label' | staticContent"
        class="col-6"
        [optional]="form.controls['email'].value || form.controls['mobile'].value"
      >
        <input
          type="text"
          [placeholder]="'contact_form.fields.phone.placeholder' | staticContent"
          formControlName="phone"
          class="inm-textinput__input"
        />
        <inm-validation-message
          [control]="form.controls['phone']"
          [label]="'contact_form.fields.phone.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>

      <inm-input-container [label]="'contact_form.fields.lastName.label' | staticContent" class="col-6">
        <input
          type="text"
          [disabled]="form.get('surname').disabled"
          [placeholder]="'contact_form.fields.lastName.placeholder' | staticContent"
          formControlName="surname"
          class="inm-textinput__input"
        />
        <inm-validation-message
          [control]="form.controls['surname']"
          [label]="'contact_form.fields.lastName.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>

      <inm-input-container
        [label]="'contact_form.fields.email.label' | staticContent"
        class="col-6"
        [optional]="form.controls['phone'].value || form.controls['mobile'].value"
      >
        <input
          type="text"
          [placeholder]="'contact_form.fields.email.placeholder' | staticContent"
          formControlName="email"
          class="inm-textinput__input"
        />
        <inm-validation-message
          [control]="form.controls['email']"
          [label]="'contact_form.fields.email.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>

      <inm-input-container
        [optional]="true"
        [label]="'contact_form.fields.jobTitle.label' | staticContent"
        class="col-6"
      >
        <input
          type="text"
          [placeholder]="'contact_form.fields.jobTitle.placeholder' | staticContent"
          formControlName="jobTitle"
          class="inm-textinput__input"
        />
        <inm-validation-message
          [control]="form.controls['jobTitle']"
          [label]="'contact_form.fields.jobTitle.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>

      <inm-input-container
        [label]="'contact_form.fields.mobile.label' | staticContent"
        class="col-6"
        [optional]="form.controls['phone'].value || form.controls['email'].value"
      >
        <input
          type="text"
          [placeholder]="'contact_form.fields.mobile.placeholder' | staticContent"
          formControlName="mobile"
          class="inm-textinput__input"
        />
        <inm-validation-message
          [control]="form.controls['mobile']"
          [label]="'contact_form.fields.mobile.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>

      <inm-input-container [label]="'contact_form.fields.suffix.label' | staticContent" class="col-6" [optional]="true">
        <inm-tree-select
          [multiple]="false"
          valueKey="value"
          labelKey="label"
          [items]="suffixOptions$ | async"
          formControlName="suffix"
          [placeholder]="'contact_form.fields.suffix.placeholder' | staticContent"
        >
        </inm-tree-select>
        <inm-validation-message
          [control]="form.controls['suffix']"
          [label]="'contact_form.fields.suffix.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>

      <inm-input-container
        [label]="'contact_form.fields.alternateEmail.label' | staticContent"
        class="col-6"
        [optional]="true"
      >
        <input
          type="text"
          [placeholder]="'contact_form.fields.alternateEmail.placeholder' | staticContent"
          formControlName="alternateEmail"
          class="inm-textinput__input"
        />
        <inm-validation-message
          [control]="form.controls['alternateEmail']"
          [label]="'contact_form.fields.alternateEmail.label' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>

      <inm-input-container [label]="'contact_form.fields.fax.label' | staticContent" class="col-6" [optional]="true">
        <input
          type="text"
          [placeholder]="'contact_form.fields.fax.placeholder' | staticContent"
          formControlName="fax"
          class="inm-textinput__input"
        />
      </inm-input-container>
    </div>
  </section>
</div>
