<div class="row mb-2">
  <div class="col-12 inm-h4-style mb-3">
    {{ contactDetails?.givenName }} {{ contactDetails?.surname }}
    <span *ngIf="tagName" class="contactTag">{{ tagName }}</span>
  </div>
  <div class="col-6">
    <ul class="mediums">
      <li class="medium" *ngFor="let medium of mediums">
        <inm-icon [name]="medium.icon" size="medium" [color]="iconColors.DarkBlue"></inm-icon>
        {{ medium.value }}
      </li>
    </ul>
  </div>
  <div class="contactInfo">
    <ul [inm-properties]="contactInfoCharacteristics" [noBorders]="true" [numCols]="1"></ul>
  </div>
</div>
