<inm-panel>
  <form role="form" [formGroup]="assignAccountDealersFormGroup">
    <inm-panel *ngIf="!reviewMode">
      <h5>
        {{ '.title' | staticContent }}
      </h5>
    </inm-panel>
    <ng-container>
      <inm-input-container [label]="'.dealer' | staticContent">
        <div class="dealerCode-icon">
          <inm-icon class="icon ms-2" name="{{ iconNames.Info }}" size="small" [tooltip]="inmADealerTooltip">
          </inm-icon>
        </div>
        <inm-search-box
          class="dealerCode-searchBox"
          formControlName="dealer"
          [placeholder]="'.dealer' | staticContent"
          [results]="dealerList"
          labelKey="accountDetail"
          (search)="searchDealer($event)"
          [multiple]="false"
        >
        </inm-search-box>
        <inm-validation-message
          *ngIf="dealerCtrl.invalid && (dealerCtrl.dirty || dealerCtrl.touched)"
          [control]="dealerCtrl"
          [label]="'.dealer' | staticContent"
        >
        </inm-validation-message>
      </inm-input-container>
    </ng-container>
  </form>
</inm-panel>
<inm-panel-actions *ngIf="!reviewMode" class="justify-content-center">
  <button inm-button kind="primary" (click)="assignDealer()">
    <span>
      {{ '.complete' | staticContent }}
    </span>
  </button>
</inm-panel-actions>
